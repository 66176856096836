<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <b-row>
        <b-col cols="3">
          <b-card class="card-accent-primary" header-tag="header">
            <b-card header-tag="header">
              <div slot="header">
                <strong>Sınavlarınız</strong>
              </div>
              <template v-for="(wdm303, wdm303_ind) in d_wdm303List">
                <b-row>
                  <b-col cols="12">
                    {{ wdm303.label }}
                    <b-badge v-if="f_checkStartableQuiz(wdm303)" variant="success" style="cursor: pointer;" @click="f_startQuiz(wdm303)"> Başla </b-badge>
                    <b-badge v-if="f_checkStartableQuiz(wdm303)" variant="warning" style="cursor: pointer;" @click="f_showQuiz(wdm303)"> Göster </b-badge>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card>
        </b-col>
        <b-col cols="9">
          <b-card class="card-accent-primary" header-tag="header">
            <b-card header-tag="header">
              <template v-if="d_activeQuiz">
                <div slot="header">
                  <strong>Aktif Sınav</strong>
                  <b-button size="sm" variant="success" class="pull-right" @click="f_saveWdm()">Sınavı Kaydet</b-button>
                  <b-button size="sm" variant="danger" class="pull-right" @click="d_activeQuiz=false;">Sınavdan Çık</b-button>
                </div>
                <wisdom-data-modal ref="wisdom_data_modal" :data="d_quizWdmData.wisdom_data" :data_type="d_quizWdmData.data_type" :option_data="d_quizWdmData.option_data" :rule_engine="false" :query_list="[]" :parameter_list="d_quizWdmData.parameter_list" :quiz_mode="true"></wisdom-data-modal>
              </template>
              <template v-if="d_showQuiz">
                <div slot="header">
                  <strong>Sınav Kağıdı</strong>
                </div>
                <wisdom-data-show :change="change" :view_mode="view_mode" :data="d_quizWdmData.wisdom_data" :data_type="d_quizWdmData.data_type" :option_data="d_quizWdmData.option_data[d_quizWdmData.data_type]"></wisdom-data-show>
              </template>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import QuizService from '@/services/quiz';
import store from '@/store';
import {
  default as router
} from '@/router';
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataShow
} from "@/components/widgets/WisdomDataShow";
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import WdmService from '@/services/wdm';

export default {
  name: 'Quiz',
  computed: {},
  mounted: function () {},
  data () {
    return {
      change: "0",
      view_mode: "table", // table, sentence, polestar, wdm_specific
      d_wdm22: {},
      d_wdm303List: [],
      d_showWdmModal: false,
      d_quizWdmData: { 'is_quiz_eligible': 0, 'option_data': {}, 'wisdom_data': {}, 'parameter_list': [], 'data_type': '' },
      d_activeQuiz: false,
      d_showQuiz: false,
      d_selectedWdm303: {}
    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    this.f_getUserWdm303List();
  },
  filters: {},
  methods: {
    f_saveWdm: function () {
      let return_data = ClsWdmrForever.turn(this.d_quizWdmData.wisdom_data, this.d_quizWdmData.option_data[this.d_quizWdmData.data_type], ['name', 'required']);
      this.d_quizWdmData.wisdom_data.label = return_data.name;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.d_quizWdmData.wisdom_data, this.d_quizWdmData.option_data[this.d_quizWdmData.data_type], ['delete']);
      let parent_wdm = { 'label': this.d_selectedWdm303.label, 'key': this.d_selectedWdm303.key, 'bucket': 'wisdom', 'type': 'wdm303' };
      let child_wdm = { 'bucket': 'wisdom', 'type': this.d_quizWdmData.data_type };
      let relation_type = 'keys';
      let data = {
        'data': this.d_quizWdmData.wisdom_data,
        'child_wdm': child_wdm,
        'parent_wdm': parent_wdm,
        'relation_type': relation_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt işleminiz yapılıyor. Lütfen bekleyiniz.' } });
      WdmService.save_child_wdmr(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_activeQuiz = false;
            msg = this.$t('wdm16.13275');
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_checkStartableQuiz: function () {
      if (this.d_activeQuiz) {
        return false;
      }
      return true;
    },
    f_getUserWdm303List: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Quiz Listeniz Hazırlanıyor' } });
      QuizService.get_user_quiz_list()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_wdm303List = resp.data.result;
          } else {
            alert(resp.data.message);
          }
        });
    },
    f_startQuiz: function (wdm303) {
      if (this.d_activeQuiz) {
        alert('you have to finish your current exam');
      } else {
        this.d_quizWdmData = { 'is_quiz_eligible': 0, 'option_data': {}, 'wisdom_data': {}, 'parameter_list': [], 'data_type': '' };
        let data = { 'wdm303_key': wdm303.key };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Quiz Veriniz Hazırlanıyor' } });
        QuizService.start_or_continue_to_quiz(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_quizWdmData.is_quiz_eligible = resp.data.result.is_quiz_eligible;
              this.d_quizWdmData.option_data = resp.data.result.option_data;
              this.d_quizWdmData.wisdom_data = resp.data.result.wisdom_data;
              this.d_quizWdmData.parameter_list = resp.data.result.parameter_list;
              this.d_quizWdmData.data_type = resp.data.result.data_type;
              if (this.d_quizWdmData.is_quiz_eligible) {
                this.d_selectedWdm303 = wdm303;
                this.d_showQuiz = false;
                this.d_activeQuiz = true;
              } else {
                let msg = 'Bu sınavı başlatamazsınız';
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sınav Başlatma İşlemi Hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
                this.$forceUpdate();
              }
            } else {
              let msg = 'Bir hata meydana geldi: ' + resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sınav Başlatma İşlemi Hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            }
          });
      }
    },
    f_showQuiz: function (wdm303) {
      if (this.d_activeQuiz) {
        alert('you have to finish your current exam');
      } else {
        this.d_quizWdmData = { 'is_quiz_eligible': 0, 'option_data': {}, 'wisdom_data': {}, 'parameter_list': [], 'data_type': '' };
        let data = { 'wdm303_key': wdm303.key };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Quiz Veriniz Hazırlanıyor' } });
        QuizService.show_quiz_data(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_quizWdmData.is_quiz_eligible = resp.data.result.is_quiz_eligible;
              this.d_quizWdmData.option_data = resp.data.result.option_data;
              this.d_quizWdmData.wisdom_data = resp.data.result.wisdom_data;
              this.d_quizWdmData.parameter_list = resp.data.result.parameter_list;
              this.d_quizWdmData.data_type = resp.data.result.data_type;
              if (this.d_quizWdmData.is_quiz_eligible) {
                this.d_selectedWdm303 = wdm303;
                this.d_showQuiz = true;
              } else {
                let msg = 'Henüz sınav kağıdı hazırlanmamış';
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sınav Kağıdınız Hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
                this.$forceUpdate();
              }
            } else {
              let msg = 'Bir hata meydana geldi: ' + resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sınav Kağıdınız Hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            }
          });
      }
    }
  },
  components: {
    WisdomDataModal,
    WisdomDataShow,
    Modal
  },
  watch: {}
};

</script>

