import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_user_quiz_list () {
    return Vue.http.get(API_BASE_URL + 'quiz/get_user_quiz_list');
  },
  start_or_continue_to_quiz (data) {
    return Vue.http.post(API_BASE_URL + 'quiz/start_or_continue_to_quiz', data);
  },
  show_quiz_data (data) {
    return Vue.http.post(API_BASE_URL + 'quiz/show_quiz_data', data);
  }
};
